/* eslint-disable react/no-unused-prop-types */
import { useEffect, useRef, useState } from 'react';
import DropBarFlow from '../../mobilePages/mobileChainLengthCalc/dropbarFlow/DropBarFlow';
import CustomChain from '../../pages/fullMountChainLengthCalculator/customChain';
import { DROP_BAR_CHAINRING_SIZES, makeCancellable, RequestType } from '../../constants';
import { useBikes } from '../../providers';

interface CustomChainParentProps {
    isMobile: boolean
    driveTrainType?: string
    setSelectedBikeType?: (value: string) => void
    setOpenBikeTypeSelection?: (value: boolean) => void
    isBikeTypeSelectionOpen?: boolean
}

const CustomChainParent = ({
    isMobile,
    driveTrainType,
    setSelectedBikeType,
    setOpenBikeTypeSelection,
    isBikeTypeSelectionOpen,
}: CustomChainParentProps) => {
    const bikes = useBikes();
    const [isFetching, setIsFetching] = useState(false);

    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);

    const fetchOEMFrameCategories = async (category: number) => {
        if (!category) return null;

        setIsFetching(true);
        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data) {
                setIsFetching(false);
                return data;
            }
        } catch (error: any) {
            if (!error.isCancelßled) {
                fetchFrameCategoriesRequest.current = null;
                setIsFetching(false);
            }
        }
        return null;
    };

    const fetchChainLengths = async ({
        selectedChainStayLength,
        selectedChainRingSize,
    }: {
        selectedChainStayLength: string;
        selectedChainRingSize: string;
    }) => {
        setIsFetching(true);
        const chainStayValue = Number(selectedChainStayLength.split('mm')[0]);
        fetchChainLengthRequest.current = makeCancellable(
            bikes.fetchChainLengths(
                Number(chainStayValue),
                DROP_BAR_CHAINRING_SIZES[DROP_BAR_CHAINRING_SIZES.length - 1].value,
                (driveTrainType === 'XPLR' || driveTrainType === 'DROP_BAR') ? 'red_xplr' : 'transmission',
            ),
        );
        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            const selectedChainLength = data[selectedChainRingSize.split('T')[0]];

            const supportedChainrings: any = [];
            Object.entries(data).forEach(([key, value]) => {
                if (value === selectedChainLength) {
                    supportedChainrings.push(`${key}T`);
                }
            });

            if (!data) {
                setIsFetching(false);
                return null;
            }

            if (selectedChainRingSize) {
                setIsFetching(false);
                return { chainLength: selectedChainLength, supportedChainrings };
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchChainLengthRequest.current = null;
                setIsFetching(false);
            }
        }
        return null;
    };

    useEffect(() => () => {
        if (fetchChainLengthRequest.current) {
            fetchChainLengthRequest.current.cancel();
        }
        if (fetchFrameCategoriesRequest.current) {
            fetchFrameCategoriesRequest.current.cancel();
        }
    }, []);

    return (
        <>
            {isMobile
            && driveTrainType
            && setSelectedBikeType
            && setOpenBikeTypeSelection
            && (
                <DropBarFlow
                    isFetching={isFetching}
                    selectedBikeType={driveTrainType}
                    setSelectedBikeType={setSelectedBikeType}
                    setOpenBikeTypeSelection={setOpenBikeTypeSelection}
                    isBikeTypeSelectionOpen={!!isBikeTypeSelectionOpen}
                    fetchChainLengths={fetchChainLengths}
                    fetchOEMFrameCategories={fetchOEMFrameCategories}
                />
            )}
            {!isMobile && driveTrainType
            && (
                <CustomChain
                    isFetching={isFetching}
                    driveTrainType={driveTrainType}
                    fetchChainLengths={fetchChainLengths}
                    fetchOEMFrameCategories={fetchOEMFrameCategories}
                />
            )}
        </>
    );
};

export default CustomChainParent;
