import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo } from 'react';
import {
    Typeahead,
} from 'react-bootstrap-typeahead';
import { Cookies } from 'react-cookie-consent';
import { Helmet } from 'react-helmet';

import CustomBike from './customBike';
import styles from './MTBFlow.module.scss';
import {
    bikeBrandType, bikeModelTypes, customValueType, frameTypes,
} from './types';

import EagleChainLengthModal from '../eagleChainLengthModal';

import {
    AXS_TEXT_GREY, CHAINRING_SIZES,
    SRAM_100,
} from '../../../constants';
import Button from '../../../components/button';
import ContentContainer from '../../../components/contentContainer';
import MobileContainer from '../../../components/mobileContainer';
import Modal from '../../../components/modal';
import Spinner from '../../../components/spinner';
import Translate, { useTranslation } from '../../../components/translate';
import Logger from '../../../Logger';
import { useAuth } from '../../../providers/auth/AuthContext';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import { checkCookieConsent } from '../../../components/analytics/Analytics';
import chainGuideHelpers from '../chainGuideHelpers';
import ToggleSelector from '../../../components/toggleSelector/ToggleSelector';
import useSetState from '../../../hooks/useSetState';

interface MTBFlowProps {
    parentState: any
    fetchBikesByBrand: (brand: string) => Promise<void>;
    fetchChainLengths: (frame: frameTypes) => Promise<any>
    fetchUDH: (frame: frameTypes) => Promise<any>
    fetchOEMFrameCategories: (category: number) => Promise<any>;
}

const DEFAULT_STATE = {
    categoryInfo: null,
    chainLength: null,
    chainRingOptions: CHAINRING_SIZES,
    chainStayLength: null,
    selectedBikeBrand: null,
    selectedBikeFrame: null,
    selectedBikeModel: null,
    selectedBikeModelYear: null,
    selectedChainringSize: null,
    selectedFrameChainLengths: null,
    selectedFrameSize: null,
};

function MTBFlow({
    parentState,
    fetchBikesByBrand,
    fetchChainLengths,
    fetchUDH,
    fetchOEMFrameCategories,
}: MTBFlowProps) {
    const navigate = useNavigate();
    const cookieConsent = Cookies.get('CookieConsent');
    const { isAuthenticated } = useAuth();
    const {
        getSupportedChainRings,
        getChainRingOptions,
        getChainInformation,
        getSelectedModel,
    } = chainGuideHelpers();
    const { search } = useLocation();
    const [state, setState] = useSetState({
        linkagePosition: '',
        openChainLengthModal: false,
        openCustomBike: false,
        openDataUnavailableModal: false,
        openNoticeModal: false,
        ...DEFAULT_STATE,
    });
    const {
        bikeModelList,
        bikeBrandsList,
        isFetching,
    } = parentState;

    const {
        selectedBikeModel,
        selectedBikeBrand,
        selectedChainringSize,
        selectedFrameSize,
        selectedBikeFrame,
        selectedBikeModelYear,
        linkagePosition,
        openCustomBike,
        openNoticeModal,
        chainLength,
        chainStayLength,
        supportedChainRings,
        openChainLengthModal,
        categoryInfo,
        openDataUnavailableModal,
        chainRingOptions,
        selectedFrameChainLengths,
    } = state;

    const translate = useTranslation();
    const bikeBrandRef = React.createRef<any>();
    const bikeModelRef = React.createRef<any>();
    const frameSizeRef = React.createRef<any>();
    const chainringSizeRef = React.createRef<any>();

    const resetSelectedFields = () => {
        setState({
            selectedBikeBrand: null,
            selectedBikeFrame: null,
            selectedBikeModel: null,
            selectedChainringSize: null,
            selectedFrameSize: null,
        });
    };

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            BikeModel: selectedBikeModel,
            Brand: selectedBikeBrand,
            ChainringSize: selectedChainringSize,
            FrameSize: selectedFrameSize,
        };

        const properties = {
            ...eventFields,
            action: 'Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Chainlength_Calculation', properties);
        }

        Logger.log('ChainLengthFormFields', eventFields);
    }

    async function onBikeBrandChange(value: any) {
        if (!value[0]) {
            resetSelectedFields();
            return;
        }

        if (value[0] === translate('MY_BIKE_NOT_LISTED')) {
            resetSelectedFields();
            setState({
                ...DEFAULT_STATE,
                openCustomBike: true,
                selectedBikeModelYear: null,
            });
            return;
        }

        try {
            await fetchBikesByBrand(value[0]);
        } catch (error) {
            Logger.error('Error fetching bikes by brand:', error);
        }

        if (typeof value[0] === 'string') {
            setState({
                ...DEFAULT_STATE,
                selectedBikeBrand: value[0],
                selectedBikeModel: null,
                selectedBikeModelYear: null,
            });
        }
    }

    function onBikeModelChange(value: Array<string | customValueType > | any) {
        if (!value[0]) {
            setState({ selectedBikeModel: null, selectedBikeModelYear: null });
            return;
        }

        if (value[0] === translate('MY_BIKE_NOT_LISTED')) {
            resetSelectedFields();
            setState({
                ...DEFAULT_STATE,
                openCustomBike: true,
                selectedBikeModelYear: null,
            });
            return;
        }

        if (typeof value[0] === 'string') {
            const lastDashIndex = value[0].lastIndexOf('-');
            const selectedBikeName = value[0].slice(0, lastDashIndex).trim();
            const selectedBikeYear = value[0].slice(lastDashIndex + 1).trim();

            const hasHighPivot = bikeModelList.find(
                (bike: bikeModelTypes) => bike.model_name === selectedBikeName && bike.high_pivot,
            );

            if (hasHighPivot) {
                setState({
                    openNoticeModal: true,
                    selectedChainringSize: null,
                    selectedFrameSize: null,
                });

                return;
            }

            setState({
                selectedBikeModel: selectedBikeName,
                selectedBikeModelYear: Number(selectedBikeYear),
                selectedChainringSize: null,
                selectedFrameSize: null,
            });
        }
    }

    function onChainringSizeChange(value: typeof CHAINRING_SIZES | string | any) {
        if (!value[0]) {
            setState({ selectedChainringSize: null });
            return;
        }

        if (value[0] === translate('NOT_LISTED')) {
            resetSelectedFields();
            setState({
                ...DEFAULT_STATE,
                openCustomBike: true,
            });
            return;
        }

        setState({ selectedChainringSize: typeof value[0] === 'string' ? value[0] : value[0].label });
    }

    const selectedModel = useMemo(
        () => getSelectedModel(
            selectedFrameSize,
            bikeModelList,
            selectedBikeBrand,
            selectedBikeModel,
            selectedBikeModelYear,
        ),
        [
            bikeModelList,
            selectedBikeBrand,
            selectedBikeModel,
            selectedBikeModelYear,
            selectedFrameSize,
            selectedBikeFrame,
        ],
    );

    function setSelectedBikeFrame() {
        const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
            ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
        );

        setState({ selectedBikeFrame: selectedBikeFrames && selectedBikeFrames.length && selectedBikeFrames[0] });

        if (selectedBikeFrames && selectedBikeFrames.length > 1) {
            setState({
                selectedBikeFrame: selectedBikeFrames.find(
                    ({ geometry_setting }: {geometry_setting: string}) => geometry_setting === linkagePosition,
                ),
            });
        }
    }

    function onFrameSizeChange(value: Array<customValueType | null> | any) {
        if (!value[0]) {
            setState({ selectedFrameSize: null });
            return;
        }

        if (value[0] === translate('NOT_LISTED')) {
            setState({
                ...DEFAULT_STATE,
                openCustomBike: true,
                selectedBikeModelYear: null,
                selectedFrameSize: null,
            });
            return;
        }

        if (typeof value[0] === 'string') {
            setState({ selectedFrameSize: value[0] });
            return;
        }

        setState({
            ...DEFAULT_STATE,
            openCustomBike: true,
            selectedFrameSize: value[0].label,
        });
    }

    async function handleFormSubmit() {
        const isValidChainRingSize = chainRingOptions.map((sizes: Record<string, unknown>) => sizes.label)
            .includes(selectedChainringSize);

        if (!selectedModel || !isValidChainRingSize || !selectedBikeFrame) {
            setState({ openDataUnavailableModal: true });
            return;
        }

        try {
            const data = await fetchUDH(selectedBikeFrame);
            if (!data) {
                throw new Error('Failed to fetch UDH');
            }

            const categoryData = await fetchOEMFrameCategories(data[0].category);
            if (!categoryData) {
                throw new Error('Failed to fetch OEM frame categories');
            }

            setState({
                categoryInfo: categoryData,
                openChainLengthModal: true,
            });

            if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
        } catch (error) {
            Logger.error('Error during form submission:', error);
            setState({ openDataUnavailableModal: true });
        }
    }

    // eslint-disable-next-line react/no-unstable-nested-components
    const CloseBtn = ({ onClick }: {onClick: () => void}) => (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
            type="button"
            onClick={onClick}
            style={{
                pointerEvents: 'auto',
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 24 24"
            >
                <path
                    fill={AXS_TEXT_GREY}
                    // eslint-disable-next-line max-len
                    d="M12 10.586l-5.293-5.293-1.414 1.414 5.293 5.293-5.293 5.293 1.414 1.414 5.293-5.293 5.293 5.293 1.414-1.414-5.293-5.293 5.293-5.293-1.414-1.414-5.293 5.293z"
                />
            </svg>
        </button>
    );

    function closeNoticeModal() {
        if (bikeModelRef.current) {
            bikeModelRef.current.clear();
        }
        setState({ selectedBikeModel: null, selectedBikeModelYear: null });
        setState({ openNoticeModal: false });
    }

    function renderContent() {
        if (!bikeBrandsList || openCustomBike) {
            return null;
        }
        const bikeBrands = bikeBrandsList.map((bike: bikeBrandType) => bike.name);
        const customBikeBrands = [translate('MY_BIKE_NOT_LISTED'), ...bikeBrands];

        const bikeModels = (selectedBikeBrand && bikeModelList)
            && Array.from(new Set(bikeModelList.map((bike: bikeModelTypes) => `${bike.model_name} - ${bike.year}`)));

        const frameSizeList = (selectedBikeBrand && bikeModelList) && Array.from(new Set(bikeModelList
            .filter(
                (bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                                && bike.model_name === selectedBikeModel
                                && bike.year === selectedBikeModelYear,
            )
            .map((model: bikeModelTypes) => model.frames)
            .flat()
            .map((frameSet: frameTypes) => frameSet.frame_size)
            .filter((frameSize: string) => frameSize)));

        const selectedBikeFrames = selectedModel && selectedModel.frames.filter(
            ({ frame_size }: { frame_size: string }) => frame_size === selectedFrameSize,
        );

        const positions = (selectedBikeFrames && selectedBikeFrames.length > 1)
            && selectedBikeFrames.filter((frames: frameTypes) => frames.geometry_setting)
                .map((frames: frameTypes) => frames.geometry_setting);

        if (positions && positions.length && !linkagePosition) {
            setState({ linkagePosition: positions[0] });
        }

        const filterOptions = (option: any, input: string, customText: string) => {
            const lowerCaseInput = input.toLowerCase();
            const isCustomOption = option === translate(customText);

            if (!isCustomOption && !option.toLowerCase().includes(lowerCaseInput) && lowerCaseInput.length > 0) {
                return false;
            }

            return true;
        };

        return (
            <ContentContainer className={styles.content}>
                <form
                    className={styles.options}
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleFormSubmit();
                    }}
                >
                    {isFetching && <Spinner />}
                    <div className={styles.row}>
                        <div className={styles.selector}>
                            <div className={styles.label}>
                                <Translate>BIKE_BRAND</Translate>
                            </div>
                            <Typeahead
                                id="bikeBrand"
                                onChange={(value) => onBikeBrandChange(value)}
                                options={customBikeBrands}
                                placeholder={translate('NEW_BIKE_BRAND_PLACEHOLDER')}
                                ref={bikeBrandRef}
                                selected={selectedBikeBrand ? [selectedBikeBrand] : []}
                                filterBy={
                                    (option, bikeFilterState) => filterOptions(
                                        option,
                                        bikeFilterState.text,
                                        'MY_BIKE_NOT_LISTED',
                                    )
                                }
                                renderMenuItemChildren={(option) => {
                                    const isCustomOption = option === translate('MY_BIKE_NOT_LISTED');
                                    return (
                                        <div className={isCustomOption ? styles.menuItemNotListed : ''}>
                                            {option.toString()}
                                        </div>
                                    );
                                }}
                            >
                                {({ onClear, selected }: {onClear: any, selected: any}) => (
                                    <div className="rbt-aux">
                                        {!!selected.length && <CloseBtn onClick={onClear} />}
                                    </div>
                                )}
                            </Typeahead>
                        </div>
                    </div>
                    {bikeModels && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>BIKE_MODEL</Translate>
                                </div>
                                <Typeahead
                                    id="bikeModel"
                                    onChange={(value) => onBikeModelChange(value)}
                                    options={[translate('MY_BIKE_NOT_LISTED'), ...bikeModels]}
                                    placeholder={translate('NEW_BIKE_MODEL_PLACEHOLDER')}
                                    ref={bikeModelRef}
                                    selected={selectedBikeModel ? [selectedBikeModel] : []}
                                    filterBy={
                                        (option, filterState) => filterOptions(
                                            option,
                                            filterState.text,
                                            'MY_BIKE_NOT_LISTED',
                                        )
                                    }
                                    renderMenuItemChildren={(option) => {
                                        const isCustomOption = option === translate('MY_BIKE_NOT_LISTED');
                                        return (
                                            <div className={isCustomOption ? styles.menuItemNotListed : ''}>
                                                {option.toString()}
                                            </div>
                                        );
                                    }}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                        </div>
                    )}
                    {((frameSizeList && frameSizeList.length > 0) || selectedFrameSize) && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>FRAME_SIZE</Translate>
                                </div>
                                <Typeahead
                                    id="frameSize"
                                    onChange={(value) => onFrameSizeChange(value)}
                                    options={[translate('NOT_LISTED'), ...(frameSizeList || [])]}
                                    placeholder={translate('NEW_FRAME_SIZE_PLACEHOLDER')}
                                    ref={frameSizeRef}
                                    selected={selectedFrameSize ? [selectedFrameSize] : []}
                                    filterBy={
                                        (option, framFilterState) => filterOptions(
                                            option,
                                            framFilterState.text,
                                            'NOT_LISTED',
                                        )
                                    }
                                    renderMenuItemChildren={(option) => {
                                        const isCustomOption = option === translate('NOT_LISTED');
                                        return (
                                            <div className={isCustomOption ? styles.menuItemNotListed : ''}>
                                                {option.toString()}
                                            </div>
                                        );
                                    }}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                            {positions && positions.length && (
                                <div className={styles.selector}>
                                    <div className={styles.bikeModelInfo}>
                                        <Translate>BIKE_MODEL_LINKAGE_INFO</Translate>
                                    </div>
                                    <ToggleSelector
                                        activeButton={linkagePosition && positions.includes(linkagePosition)
                                            ? linkagePosition
                                            : ''}
                                        buttonClassName={styles.toggleButton}
                                        buttons={positions.map(
                                            (position: any) => ({ label: position, value: position }),
                                        )}
                                        color={SRAM_100}
                                        className={styles.toggler}
                                        onToggle={(value) => setState({ linkagePosition: value })}
                                    />

                                </div>
                            )}
                        </div>
                    )}
                    {((frameSizeList && frameSizeList.length > 0) || selectedChainringSize) && (
                        <div className={styles.row}>
                            <div className={styles.selector}>
                                <div className={styles.label}>
                                    <Translate>CHAINRING_SIZE</Translate>
                                </div>
                                <Typeahead
                                    id="chainringSize"
                                    onChange={(value) => onChainringSizeChange(value)}
                                    options={[translate('NOT_LISTED'), ...chainRingOptions]}
                                    placeholder={translate('NEW_CHAINRING_SIZE_PLACEHOLDER')}
                                    ref={chainringSizeRef}
                                    selected={selectedChainringSize ? [selectedChainringSize] : []}
                                    filterBy={(option: any, chainRingFilterState) => {
                                        if (option.label) {
                                            return filterOptions(option.label, chainRingFilterState.text, 'NOT_LISTED');
                                        }
                                        return filterOptions(option, chainRingFilterState.text, 'NOT_LISTED');
                                    }}
                                    renderMenuItemChildren={(option: any) => {
                                        const isCustomOption = option === translate('NOT_LISTED');
                                        return (
                                            <div className={isCustomOption ? styles.menuItemNotListed : ''}>
                                                {option.label ? option?.label.toString() : option.toString()}
                                            </div>
                                        );
                                    }}
                                >
                                    {({ onClear, selected }: {onClear: any, selected: any}) => (
                                        <div className="rbt-aux">
                                            {!!selected.length && <CloseBtn onClick={onClear} />}
                                        </div>
                                    )}
                                </Typeahead>
                            </div>
                        </div>
                    )}
                    {selectedChainringSize && selectedBikeBrand && selectedBikeModel && selectedFrameSize && (
                        <div className={styles.footer}>
                            <button className={styles.nextButton} type="submit">
                                <Translate>SUBMIT</Translate>
                            </button>
                        </div>
                    )}
                </form>
            </ContentContainer>
        );
    }

    function renderSelectionUnvailableModal() {
        return (
            <Modal
                className={styles.noticeModalContainer}
                contentClassName={styles.infoContent}
                isOpen={openDataUnavailableModal}
                header="SELECTION_UNAVAILABLE"
                hideCloseButton
                hideImage
                contentLabel=""
            >
                <div className={styles.noticeContainer}>
                    <div>
                        <Translate>NO_DATA_CHAINLENGTH_CALCULATOR</Translate>
                    </div>
                    <div className={styles.buttonContainer}>
                        <Button
                            color={AXS_TEXT_GREY}
                            inverse
                            onClick={() => setState({ openDataUnavailableModal: false })}
                        >
                            <Translate>NO</Translate>
                        </Button>
                        <Button onClick={() => setState({ openCustomBike: true, openDataUnavailableModal: false })}>
                            <Translate>YES</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    function renderIdlerPulleyNoticeModal() {
        return (
            <Modal
                contentLabel=""
                className={styles.noticeModalContainer}
                contentClassName={styles.infoContent}
                isOpen={openNoticeModal}
                header="Notice"
                hideCloseButton
                hideImage
            >
                <div className={styles.noticeContainer}>
                    <Translate>IDLER_PULLEY_NOTICE</Translate>
                    <div className={styles.buttonContainer}>
                        <a
                            className={styles.link}
                            // eslint-disable-next-line max-len
                            href="https://docs.sram.com/en-US/publications/5jblJ4SRpeHwjcuWG1vPy4#hashItem=idler-pulley-frame-setup-information"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <Button color={AXS_TEXT_GREY} inverse onClick={() => setState({ openNoticeModal: false })}>
                                <Translate>VIEW_PRODUCT_MANUAL</Translate>
                            </Button>
                        </a>
                        <Button onClick={() => closeNoticeModal()}>
                            <Translate>CLOSE</Translate>
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }

    const checkUrlParams = () => {
        const queryParam = new URLSearchParams(search);
        const urlBikeBrand = queryParam.get('selectedBikeBrand');

        // Check if the bike brand is in the list of bike brands
        const bikeBrands = bikeBrandsList?.map((bike: bikeBrandType) => bike.name);
        if (!bikeBrands) return;

        if (urlBikeBrand && !bikeBrands.includes(urlBikeBrand)) {
            navigate('/guides/chain/calculator');
            return;
        }

        onBikeBrandChange([urlBikeBrand]).then(() => {
            setState({
                linkagePosition: queryParam.get('linkagePosition'),
                selectedBikeModel: queryParam.get('selectedBikeModel'),
                selectedBikeModelYear: queryParam.get('selectedBikeModelYear')
                    ? Number(queryParam.get('selectedBikeModelYear'))
                    : null,
                selectedChainringSize: queryParam.get('selectedChainringSize'),
                selectedFrameSize: queryParam.get('selectedFrameSize'),
            });
        });
    };

    useEffect(() => {
        if (search && bikeBrandsList?.length) checkUrlParams();
    }, [bikeBrandsList]);

    useEffect(() => {
        // When the user selects a new frame size set the selected bike frame
        setSelectedBikeFrame();
    }, [linkagePosition, selectedFrameSize, selectedModel]);

    useEffect(() => {
        // When the user selects a new frame we fetch the chainlengths and chainring options
        const fetchChainLengthsRequests = async () => {
            try {
                const data = await fetchChainLengths(selectedBikeFrame);
                if (!data) {
                    throw new Error('Failed to fetch chain lengths');
                }
                setState({
                    chainRingOptions: getChainRingOptions(data),
                    selectedFrameChainLengths: data,
                });
            } catch (error) {
                Logger.error('Error fetching chain lengths', error);
            }
        };

        if (selectedBikeFrame) {
            fetchChainLengthsRequests();
        }
    }, [selectedBikeFrame]);

    useEffect(() => {
        // When the user selects a chainring size we calculate the chainlength
        setState({
            chainLength: getChainInformation(selectedFrameChainLengths, selectedChainringSize),
            chainStayLength: selectedBikeFrame?.chainstay_length,
            supportedChainRings: getSupportedChainRings(selectedFrameChainLengths, selectedChainringSize),
        });
    }, [selectedChainringSize, selectedFrameChainLengths]);

    return (
        <>
            <Helmet>
                <title>EAGLE AXS™ TRANSMISSION Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <ContentContainer className={styles.optionsContainer}>
                {!openCustomBike && (
                    <MobileContainer className={styles.description}>
                        <Translate>CHAIN_LENGTH_DESCRIPTION</Translate>
                    </MobileContainer>
                )}
                {renderContent()}
                {renderSelectionUnvailableModal()}
                {renderIdlerPulleyNoticeModal()}
                <CustomBike
                    isOpen={openCustomBike}
                    onBack={() => setState({
                        openCustomBike: !openCustomBike,
                        ...DEFAULT_STATE,
                    })}
                />
                {openChainLengthModal && (
                    <EagleChainLengthModal
                        bike={`${selectedBikeBrand} ${selectedBikeModel}`}
                        chainLength={chainLength}
                        chainStayLength={chainStayLength}
                        isOpen={openChainLengthModal}
                        onClose={() => setState({ openChainLengthModal: !openChainLengthModal })}
                        setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                        setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                        setUpCogImage={categoryInfo && categoryInfo.setup_cog_position_image}
                        setUpKeyPos={categoryInfo && categoryInfo.setup_key_position}
                        setUpKeyPosImage={categoryInfo && categoryInfo.setup_key_position_image}
                        supportedChainrings={supportedChainRings}
                    />
                )}
            </ContentContainer>
        </>
    );
}

export default MTBFlow;
