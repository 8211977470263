import {
    useEffect,
    useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { FadeLoader } from 'react-spinners';

import { useSearchParams } from 'react-router-dom';

import CustomBikeMobile from './customBikeMobile/CustomBikeMobile';
import styles from './mobileChainLengthCalc.module.scss';
import HowToMeasureInfoModal from './modals/HowToMeasureInfoModal';
import SelectionModalAlt from './modals/SelectionModalAlt';

import ContentContainer from '../../components/contentContainer';
import MtbFlowParent from '../../components/ChainGuideFetchComponents/MtbFlowParent';
import CustomChainParent from '../../components/ChainGuideFetchComponents/CustomChainParent';
import MobileContainer from '../../components/mobileContainer';
import Spinner from '../../components/spinner';
import Translate from '../../components/translate';

const MobileChainLengthCalc = () => {
    const [bikeType, setBikeType] = useState('');
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [customBike, setCustomBike] = useState<boolean>(false);
    const [openSelectionModal, setOpenSelectionModal] = useState(false);
    const [modalView, setModalView] = useState<string>('');
    const [isFetching, setIsFetching] = useState(false);
    const [currentQueryParameters, setSearchParams] = useSearchParams();

    const handleSetState = (value: any) => {
        if (!value) return;
        if (modalView === 'BIKE_TYPE') {
            setBikeType(value);
        }
    };

    const renderOptions = (sectionHeader: string, value: any, disabled?: boolean) => {
        const onClick = () => {
            setOpenSelectionModal(!openSelectionModal);
            setModalView(sectionHeader);
        };

        return (
            <ContentContainer className={`${styles.optionsContainer} ${disabled ? styles.disabled : ''}`}>
                <SelectionModalAlt
                    open={openSelectionModal && modalView === 'BIKE_TYPE'}
                    onClose={() => setOpenSelectionModal(false)}
                    setOption={handleSetState}
                    list={['DROP_BAR', 'MTB/E-MTB']}
                />
                <div
                    role="button"
                    className={styles.row}
                    tabIndex={0}
                    onClick={() => {
                        if (!disabled) {
                            onClick();
                        }
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !disabled) {
                            onClick();
                        }
                    }}
                >
                    <div className={styles.selectorTitle}>
                        <Translate>{sectionHeader}</Translate>
                    </div>
                    <div className={styles.selectorTitle}>
                        <Translate>{value || '-- --'}</Translate>
                    </div>
                </div>
            </ContentContainer>
        );
    };

    const renderHeader = () => (
        <MobileContainer className={styles.mobileContainer}>
            <div className={styles.description}>
                <Translate>FULL_MOUNT_CHAIN_CALC_DESC</Translate>
                <button
                    type="button"
                    onClick={() => setShowInfoModal(!showInfoModal)}
                    className={styles.infoLink}
                >
                        Learn More
                </button>
            </div>
        </MobileContainer>
    );

    const checkUrlParams = () => {
        const urlBikeType = currentQueryParameters.get('rd_type');
        const urlBikeBrand = currentQueryParameters.get('selectedBikeBrand');

        if (urlBikeBrand) {
            setBikeType('MTB/E-MTB');
        }

        if (urlBikeType) {
            setBikeType('DROP_BAR');
        }
    };

    useEffect(() => {
        if (currentQueryParameters) checkUrlParams();
    }, []);

    useEffect(() => {
        if (customBike) {
            setBikeType('MTB/E-MTB');
        }
    }, [customBike]);

    return (
        <>
            <Helmet>
                <title>Full Mount Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            { isFetching && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}
            <HowToMeasureInfoModal
                open={showInfoModal}
                onClose={() => setShowInfoModal(!showInfoModal)}
            />
            <ContentContainer className={styles.container}>
                {renderHeader()}
                {renderOptions('BIKE_TYPE', bikeType, customBike)}
                {bikeType && bikeType === 'DROP_BAR' && (
                    <CustomChainParent
                        isMobile
                        // driveTrainType="ROAD"
                        driveTrainType={bikeType}
                        setSelectedBikeType={setBikeType}
                        setOpenBikeTypeSelection={setOpenSelectionModal}
                        isBikeTypeSelectionOpen={openSelectionModal}
                    />
                )}
                {bikeType && bikeType === 'MTB/E-MTB' && !customBike && (
                    <MtbFlowParent
                        isMobile
                        setCustomBike={setCustomBike}
                        setSelectedBikeType={setBikeType}
                        setOpenBikeTypeSelection={setOpenSelectionModal}
                        isBikeTypeSelectionOpen={openSelectionModal}
                    />
                )}
                {customBike && (
                    <CustomBikeMobile
                        isOpen={customBike}
                        setSelectedBikeType={setBikeType}
                        setCustomBike={setCustomBike}
                    />
                )}

            </ContentContainer>
        </>
    );
};

export default MobileChainLengthCalc;
