import React, { useEffect, useRef } from 'react';
import MTBFlow from '../../pages/fullMountChainLengthCalculator/MTBFlow';
import MTBFlowMobile from '../../mobilePages/mobileChainLengthCalc/mtbFlowMobile/MTBFlowMobile';
import { useSetState } from '../../hooks';
import { useBikes } from '../../providers';
import { CHAINRING_SIZES, makeCancellable, RequestType } from '../../constants';
import Logger from '../../Logger';
import { frameTypes } from '../../pages/fullMountChainLengthCalculator/MTBFlow/types';

const MtbFlowParent = ({
    isMobile,
    setCustomBike,
    setSelectedBikeType,
    setOpenBikeTypeSelection,
    isBikeTypeSelectionOpen,
}: {
    isMobile: boolean
    setCustomBike?: (value: boolean) => void
    setSelectedBikeType?: (value: string) => void
    setOpenBikeTypeSelection?: (value: boolean) => void
    isBikeTypeSelectionOpen?: boolean
    }) => {
    const bikes = useBikes();
    const [state, setState] = useSetState({
        bikeBrandsList: null,
        bikeModelList: [],
        categoryInfo: null,
        chainRingOptions: CHAINRING_SIZES,
        isCalculating: false,
        isFetching: false,
        isMobile: false,
        openChainLengthModal: false,
        openDataUnavailableModal: false,
        selectedFrameChainLengths: null,
    });
    const {
        bikeBrandsList,
    } = state;

    const fetchBikesByBrandRequest = useRef<RequestType | null>(null);
    const fetchChainLengthRequest = useRef<RequestType | null>(null);
    const fetchBikeBrandsRequest = useRef<RequestType | null>(null);
    const fetchFrameCategoriesRequest = useRef<RequestType | null>(null);
    const fetchUdhRequest = useRef<RequestType | null>(null);

    async function fetchBikeBrands() {
        setState({ isFetching: true });
        fetchBikeBrandsRequest.current = makeCancellable(bikes.fetchBikeModelBrands());

        try {
            const data = await fetchBikeBrandsRequest.current.promise;

            setState({ bikeBrandsList: data, isFetching: false });

            fetchBikeBrandsRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikeBrandsRequest.current = null;
                setState({ isFetching: false });
                Logger.error('fetchBikeBrands', error);
            }

            return null;
        }
    }

    const fetchBikesByBrand = async (brandName: string) => {
        const selectedBrand = bikeBrandsList?.find((brand: any) => brand.name === brandName);
        setState({ isFetching: true });
        if (!selectedBrand) return null;
        fetchBikesByBrandRequest.current = makeCancellable(bikes.fetchBikesByBrandId(selectedBrand.id));

        try {
            const data = await fetchBikesByBrandRequest.current.promise;

            if (data) setState({ bikeModelList: data, isFetching: false });

            fetchBikesByBrandRequest.current = null;

            return data;
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchBikesByBrandRequest.current = null;
                Logger.error('fetchBikesByBrand', error);
                setState({ isFetching: false });
            }

            return null;
        }
    };

    const fetchChainLengths = async (frame: frameTypes) => {
        if (!frame) return null;

        setState({ isFetching: true });
        fetchChainLengthRequest.current = makeCancellable(bikes.fetchChainLengthsByFrame(frame.id));

        try {
            const data = await fetchChainLengthRequest.current.promise;

            fetchChainLengthRequest.current = null;

            if (data) {
                setState({ isFetching: false });
                return data;
            }

            if (!data) {
                setState({ isFetching: false });
                return null;
            }
        } catch (error: any) {
            Logger.error('Error fetching chain lengths', error);
            setState({ isFetching: false });
        }
        return null;
    };

    const fetchOEMFrameCategories = async (category: number) => {
        if (!category) return null;

        setState({ isFetching: true });
        fetchFrameCategoriesRequest.current = makeCancellable(bikes.fetchOEMFrameCategories(category));

        try {
            const data = await fetchFrameCategoriesRequest.current.promise;

            fetchFrameCategoriesRequest.current = null;

            if (data && Object.keys(data).length) {
                setState({ isFetching: false });
                return data;
            }

            if (!data || !Object.keys(data).length) {
                setState({ isFetching: false, openDataUnavailableModal: true });
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchFrameCategoriesRequest.current = null;
                setState({ isFetching: false });
            }
        }
        return null;
    };

    const fetchUDH = async (frame: frameTypes) => {
        if (!frame) return null;

        setState({ isFetching: true });
        fetchUdhRequest.current = makeCancellable(bikes.fetchUdhByFrame(frame.id));

        try {
            const data = await fetchUdhRequest.current.promise;

            fetchUdhRequest.current = null;

            if (data.length && data[0].category) {
                setState({ isFetching: false });
                return data;
            }

            if (!data.length || !data[0].category) {
                setState({ isFetching: false });
                return null;
            }
        } catch (error: any) {
            if (!error.isCancelled) {
                fetchUdhRequest.current = null;
                setState({ isFetching: false });
            }
        }
        return null;
    };

    useEffect(() => {
        if (!bikeBrandsList) fetchBikeBrands();

        return () => {
            if (fetchBikeBrandsRequest.current) {
                fetchBikeBrandsRequest.current.cancel();
                fetchBikeBrandsRequest.current = null;
            }

            if (fetchBikesByBrandRequest.current) {
                fetchBikesByBrandRequest.current.cancel();
                fetchBikesByBrandRequest.current = null;
            }

            if (fetchFrameCategoriesRequest.current) {
                fetchFrameCategoriesRequest.current.cancel();
                fetchFrameCategoriesRequest.current = null;
            }

            if (fetchUdhRequest.current) {
                fetchUdhRequest.current.cancel();
                fetchUdhRequest.current = null;
            }

            if (fetchChainLengthRequest.current) {
                fetchChainLengthRequest.current.cancel();
                fetchChainLengthRequest.current = null;
            }
        };
    }, []);

    return (
        <>
            {(isMobile && setCustomBike && setSelectedBikeType && setOpenBikeTypeSelection)
            && (
                <MTBFlowMobile
                    fetchBikesByBrand={fetchBikesByBrand}
                    fetchChainLengths={fetchChainLengths}
                    fetchUDH={fetchUDH}
                    fetchOEMFrameCategories={fetchOEMFrameCategories}
                    setCustomBike={setCustomBike}
                    setSelectedBikeType={setSelectedBikeType}
                    setOpenBikeTypeSelection={setOpenBikeTypeSelection}
                    isBikeTypeSelectionOpen={isBikeTypeSelectionOpen}
                    parentState={state}
                />
            ) }
            {!isMobile
            && (
                <MTBFlow
                    parentState={state}
                    fetchBikesByBrand={fetchBikesByBrand}
                    fetchChainLengths={fetchChainLengths}
                    fetchUDH={fetchUDH}
                    fetchOEMFrameCategories={fetchOEMFrameCategories}
                />
            )}
        </>
    );
};
export default MtbFlowParent;
