import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import styles from './fullMountChainLengthCalculator.module.scss';

import { BLACK } from '../../constants';
import { useSetState } from '../../hooks';
import ContentContainer from '../../components/contentContainer';
import DesktopContainer from '../../components/desktopContainer';
import Translate from '../../components/translate';
import CustomChainParent from '../../components/ChainGuideFetchComponents/CustomChainParent';
import MtbFlowParent from '../../components/ChainGuideFetchComponents/MtbFlowParent';

function FullMountChainLengthCalculator() {
    const { search } = useLocation();
    const [state, setState] = useSetState({
        bikeType: '',
        driveTrainType: '',
        openDataUnavailableModal: false,
    });

    useEffect(() => {
        setState({
            bikeType: state.bikeType,
            driveTrainType: '',
            openDataUnavailableModal: false,
        });
    }, [state.bikeType]);

    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const urlBikeBrand = urlParams.get('selectedBikeBrand');

        if (urlBikeBrand) {
            setState({
                bikeType: 'MTB/E-MTB',
            });
        }
    }, []);

    function renderOptions(sectionHeader: string, btn1Text: string, btn2Text: string, key: string) {
        return (
            <ContentContainer className={styles.optionsContainer}>
                <div className={styles.row}>
                    <div className={styles.selector}>
                        <div className={styles.label}>
                            <Translate>{sectionHeader}</Translate>
                        </div>
                        <div className={styles.selectionButtonContainer}>
                            <button
                                className={styles.selectionButton}
                                onClick={() => setState({ ...state, [key]: btn1Text })}
                                type="button"
                                style={{
                                    backgroundColor:
                                    state[key as keyof typeof state] === btn1Text ? BLACK : 'transparent',
                                    color: state[key as keyof typeof state] === btn1Text ? 'white' : BLACK,
                                }}
                            >
                                <Translate>{btn1Text}</Translate>
                            </button>
                            <button
                                className={styles.selectionButton}
                                onClick={() => {
                                    setState({ ...state, [key]: btn2Text });
                                }}
                                type="button"
                                style={{
                                    backgroundColor:
                                    state[key as keyof typeof state] === btn2Text ? BLACK : 'transparent',
                                    color: state[key as keyof typeof state] === btn2Text ? 'white' : BLACK,
                                }}
                            >
                                <Translate>{btn2Text}</Translate>
                            </button>
                        </div>
                    </div>
                </div>
            </ContentContainer>
        );
    }

    return (
        <>
            <Helmet>
                <title>Full Mount Chain and Setup Key Guide</title>
                <meta
                    name="description"
                    content="Calculate the perfect chain length for your
                    Eagle Transmission with the Eagle AXS™ Transmission Chain and Setup Key Guide."
                />
            </Helmet>
            <ContentContainer className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.headerDescription}>
                        <div className={styles.title}>
                            <Translate>FULL_MOUNT_CHAIN_LENGTH_GUIDE</Translate>
                        </div>
                        <DesktopContainer className={styles.description}>
                            <Translate>FULL_MOUNT_CHAIN_CALC_DESC</Translate>
                        </DesktopContainer>

                    </div>
                </div>
                {renderOptions('BIKE_TYPE', 'DROP_BAR', 'MTB/E-MTB', 'bikeType')}
                {state.bikeType === 'DROP_BAR' && (
                    renderOptions('DRIVETRAIN_TYPE', 'XPLR', 'EAGLE_TRANSMISSION', 'driveTrainType'))}
                {state.bikeType === 'MTB/E-MTB' && (
                    <MtbFlowParent isMobile={false} />
                )}
                {state.driveTrainType !== ''
                && <CustomChainParent isMobile={false} driveTrainType={state.driveTrainType} />}
            </ContentContainer>
        </>
    );
}

export default FullMountChainLengthCalculator;
