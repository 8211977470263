import { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Cookies } from 'react-cookie-consent';

import styles from './CustomChain.module.scss';

import XPLRChainLengthModal from '../XPLRChainLengthModal/XPLRChainLengthModal';
import EagleChainLengthModal from '../eagleChainLengthModal/EagleChainLengthModal';

import { imageHowToMeasure } from '../../../assets';
import {
    CHAINSTAY_LENGTH,
    DROP_BAR_CHAINRING_SIZES, DROP_BAR_CHAINSTAY_LENGTH,
} from '../../../constants';
import { checkCookieConsent } from '../../../components/analytics/Analytics';
import ContentContainer from '../../../components/contentContainer';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import Modal from '../../../components/modal';
import Translate, { useTranslation } from '../../../components/translate';
import TranslateOption from '../../../components/translateOption';
import Spinner from '../../../components/spinner';
import Logger from '../../../Logger';
import { useAuth } from '../../../providers';

interface CustomChainProps {
    isFetching: boolean;
    driveTrainType: string;
    fetchChainLengths: ({
        selectedChainStayLength,
        selectedChainRingSize,
    }: {
        selectedChainStayLength: string;
        selectedChainRingSize: string;
    }) => Promise<{ supportedChainrings: any; chainLength: any } | null>;
    fetchOEMFrameCategories: (category: number) => Promise<any>;
}

function CustomChain({
    isFetching,
    driveTrainType,
    fetchChainLengths,
    fetchOEMFrameCategories,
}: CustomChainProps) {
    const { isAuthenticated } = useAuth();
    const cookieConsent = Cookies.get('CookieConsent');
    const [chainLength, setChainLength] = useState< null | number>(null);
    const [chainRingSize, setChainRingSize] = useState<string>('');
    const [chainStayLength, setChainStayLength] = useState<string>('');
    const [supportedChainRings, setSupportedChainRings] = useState<[]>([]);
    const [openSetUpInfoModal, setOpenSetUpInfoModal] = useState(false);
    const [openChainLengthModal, setOpenChainLengthModal] = useState<boolean>(false);
    const [categoryInfo, setCategoryInfo] = useState<any>(null);

    const translate = useTranslation();
    const isEagleTransmission = driveTrainType === 'EAGLE_TRANSMISSION';

    const chainStayOptions = isEagleTransmission ? CHAINSTAY_LENGTH : DROP_BAR_CHAINSTAY_LENGTH;
    const chainRingOptions = DROP_BAR_CHAINRING_SIZES;

    useEffect(() => {
        setChainStayLength('');
        setOpenChainLengthModal(false);
        setChainRingSize('');
        setCategoryInfo(null);
    }, []);

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: chainStayLength,
            CustomChainringSize: chainRingSize,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    async function calculateChainLength() {
        try {
            const chainLengths = await fetchChainLengths({
                selectedChainRingSize: chainRingSize,
                selectedChainStayLength: chainStayLength,
            });
            if (!chainLengths) {
                throw new Error('Error fetching chain lengths');
            }
            setSupportedChainRings(chainLengths.supportedChainrings);
            setChainLength(chainLengths.chainLength);

            const category = driveTrainType === 'XPLR' ? 4 : 1;
            // Need to handle operations normally handled in fetchOEMFrameCategories
            const frameCategories = await fetchOEMFrameCategories(category);
            if (!frameCategories) {
                throw new Error('Error fetching frame categories');
            }

            setCategoryInfo(frameCategories);
            setOpenChainLengthModal(true);

            if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
        } catch (error: any) {
            Logger.error('Error fetching chain lengths', error);
        }
    }

    function renderInfoModal() {
        return (
            <Modal
                className={styles.modalContainer}
                contentClassName={styles.infoContent}
                contentLabel="InfoModal"
                isOpen={openSetUpInfoModal}
                header="HOW_TO_MEASURE"
                hideImage
                onClose={() => setOpenSetUpInfoModal(false)}
            >
                <div className={styles.setupGuideContainer}>
                    <div className={styles.infoContainer}>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINSTAY_LENGTH</Translate>
                        </div>
                        <div className={styles.infoSubHeader}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE_TITLE</Translate>
                        </div>
                        <div className={styles.infoDetail}>
                            <Translate>HOW_TO_MEASURE_CHAINRING_SIZE</Translate>
                        </div>
                    </div>
                    <div className={styles.imageContainer}>
                        <img
                            alt="Setup Guide"
                            className={styles.image}
                            src={imageHowToMeasure}
                        />
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <>
            {!isEagleTransmission && (
                <XPLRChainLengthModal
                    bike={translate('DROP_BAR_XPLR_FULL_MOUNT')}
                    chainRingSize={chainRingSize}
                    chainLength={chainLength}
                    chainStayLength={Number(chainStayLength)}
                    isOpen={openChainLengthModal}
                    onClose={() => setOpenChainLengthModal(false)}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    supportedChainrings={supportedChainRings}
                />
            )}
            {isEagleTransmission && (
                <EagleChainLengthModal
                    bike={translate('EAGLE_TRANSMISSION')}
                    chainLength={chainLength}
                    chainStayLength={Number(chainStayLength)}
                    isOpen={openChainLengthModal}
                    onClose={() => setOpenChainLengthModal(false)}
                    supportedChainrings={supportedChainRings}
                    setUpCogPosition={categoryInfo && categoryInfo.setup_cog_position}
                    setUpCogPositionText={categoryInfo && categoryInfo.setup_cog_position_text}
                    setUpCogImage={categoryInfo && categoryInfo.setup_cog_position_image}
                    setUpKeyPos={categoryInfo && categoryInfo.setup_key_position_text}
                    setUpKeyPosImage={categoryInfo && categoryInfo.setup_key_position_image}
                />
            )}
            <ContentContainer className={styles.optionsContainer}>
                { isFetching && <Spinner />}
                <div className={styles.row}>
                    <div className={styles.selector}>
                        <div className={styles.label}>
                            <Translate>CHAINSTAY_LENGTH_AND_CHAINRING_SIZE</Translate>
                            <button
                                className={styles.helpButton}
                                onClick={() => setOpenSetUpInfoModal(true)}
                                id="infoButton"
                                type="button"
                                data-testid="customChain-infoButton"
                            >
                                <InfoOutlinedIcon />
                            </button>
                        </div>
                        <div className={styles.content}>
                            <select
                                data-testid="chainstay-length"
                                className={styles.inputField}
                                onChange={(event) => setChainStayLength(event.target.value)}
                                value={chainStayLength}
                            >
                                <TranslateOption value="">CHAINSTAY_SELECTION</TranslateOption>
                                {chainStayOptions.map(({ label, value }) => (
                                    <option key={label} value={value}>{label}</option>
                                ))}
                            </select>
                            <select
                                data-testid="chainring-size"
                                className={styles.inputField}
                                onChange={(event) => setChainRingSize(event.target.value)}
                                value={chainRingSize}
                            >
                                <TranslateOption value="">CHAINRING_SELECTION</TranslateOption>
                                {chainRingOptions.map(({ label }) => (
                                    <option key={label} value={label}>{label}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {renderInfoModal()}
                {chainStayLength !== '' && chainRingSize !== '' && (
                    <div className={styles.footer}>
                        <button
                            className={styles.submitButton}
                            onClick={() => calculateChainLength()}
                            type="button"
                        >
                            <Translate>SUBMIT</Translate>
                        </button>
                    </div>
                )}
            </ContentContainer>
        </>
    );
}

export default CustomChain;
