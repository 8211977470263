import { bikeModelTypes } from './MTBFlow/types';

const chainGuideHelpers = () => {
    const getSupportedChainRings = (selectedFrameChainLengths: any, selectedChainRingSize: any) => {
        if (!selectedFrameChainLengths || !selectedChainRingSize) return null;

        const selectedChainLength = selectedFrameChainLengths[selectedChainRingSize.split('T')[0]];

        const supportedChainrings: Array<string> = [];
        Object.entries(selectedFrameChainLengths).forEach(([key, value]) => {
            if (value === selectedChainLength) {
                supportedChainrings.push(`${key}T`);
            }
        });

        return supportedChainrings;
    };

    const getChainRingOptions = (chainLengths: Record<string, unknown>) => {
        const options = Object.keys(chainLengths).map((key) => ({ label: `${key}T`, value: key }));
        return options;
    };

    const getChainInformation: any = (selectedFrameChainLengths: any, selectedChainringSize: any) => {
        if (!selectedFrameChainLengths || !selectedChainringSize) return null;

        const selectedChainLength = selectedFrameChainLengths[selectedChainringSize.split('T')[0]];

        return selectedChainLength;
    };

    const getSelectedModel = (
        selectedFrameSize: any,
        bikeModelList: any,
        selectedBikeBrand: any,
        selectedBikeModel: any,
        selectedBikeModelYear: any,
    ) => {
        if (!selectedFrameSize || !bikeModelList) return null;

        return bikeModelList
            .find((bike: bikeModelTypes) => bike.brand.name === selectedBikeBrand
                && bike.model_name === selectedBikeModel
                && bike.year === selectedBikeModelYear
                && bike.frames.find(({ frame_size }) => frame_size === selectedFrameSize));
    };

    return {
        getChainInformation,
        getChainRingOptions,
        getSelectedModel,
        getSupportedChainRings,
    };
};

export default chainGuideHelpers;
