import {
    useEffect,
    useState,
} from 'react';
import { Cookies } from 'react-cookie-consent';
import { FadeLoader } from 'react-spinners';
import { useNavigate, useSearchParams } from 'react-router-dom';

import styles from '../mobileChainLengthCalc.module.scss';
import DataUnavailableModal from '../modals/DataUnavailableModal';
import SelectionModal from '../modals/SelectionModal';
import SelectionModalAlt from '../modals/SelectionModalAlt';

import {
    CHAINSTAY_LENGTH,
    DROP_BAR_CHAINRING_SIZES, DROP_BAR_CHAINSTAY_LENGTH,
    SRAM_500,
    WHITE,
} from '../../../constants';
import { checkCookieConsent } from '../../../components/analytics/Analytics';
import ContentContainer from '../../../components/contentContainer';
import FirebaseAnalytics from '../../../components/firebaseAnalytics';
import Translate from '../../../components/translate';
import Spinner from '../../../components/spinner';
import { useSetState } from '../../../hooks';
import Logger from '../../../Logger';
import { useAuth } from '../../../providers';

interface DropBarFlowProps {
    isFetching: boolean,
    selectedBikeType: string,
    setSelectedBikeType: (value: string) => void,
    setOpenBikeTypeSelection: (value: boolean) => void,
    isBikeTypeSelectionOpen: boolean,
    fetchChainLengths: ({
        selectedChainStayLength,
        selectedChainRingSize,
    } : {
        selectedChainStayLength: string,
        selectedChainRingSize: string
    }) => Promise<{supportedChainrings: any, chainLength: any} | null>,
    fetchOEMFrameCategories: (category: number) => Promise<any>,
}

const DropBarFlow = ({
    isFetching,
    selectedBikeType,
    setSelectedBikeType,
    setOpenBikeTypeSelection,
    isBikeTypeSelectionOpen,
    fetchChainLengths,
    fetchOEMFrameCategories,
}: DropBarFlowProps) => {
    const { isAuthenticated } = useAuth();

    const navigate = useNavigate();
    const cookieConsent = Cookies.get('CookieConsent');
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const [state, setState] = useSetState({
        openSelectionModal: false,
        selectedChainRingSize: '',
        selectedChainStayLength: '',
        selectedDriveTrainType: '',
        showDataUnavailableModal: false,
        showInfoModal: false,
    });
    const [modalView, setModalView] = useState('');
    const {
        selectedChainRingSize,
        selectedChainStayLength,
        showDataUnavailableModal,
        selectedDriveTrainType,
        openSelectionModal,
    } = state;

    const isEagleTransmission = selectedDriveTrainType === 'EAGLE_TRANSMISSION';
    const disableCalcButton = !selectedChainStayLength
                    || !selectedChainRingSize
                    || isFetching
                    || !selectedDriveTrainType
                    || !selectedBikeType;
    const chainStayOptions = isEagleTransmission ? CHAINSTAY_LENGTH : DROP_BAR_CHAINSTAY_LENGTH;
    const chainRingOptions = DROP_BAR_CHAINRING_SIZES;

    const clearParams = () => {
        const emptyParams : URLSearchParams = new URLSearchParams();
        setSearchParams(emptyParams);
    };

    const resetState = () => {
        setState({
            chainLength: '',
            chainStayLength: '',
            openSelectionModal: false,
            selectedChainRingSize: '',
            selectedChainStayLength: '',
            selectedDriveTrainType: '',
            showBikeSetup: false,
            showInfoModal: false,
        });
    };

    async function eventLogger() {
        // Log events to GA and Logger
        const eventFields = {
            ChainStayLength: selectedChainStayLength,
            CustomChainringSize: selectedChainRingSize,
        };

        const properties = {
            ...eventFields,
            action: 'Custom Chainlength Calculation',
            category: 'Form Submission',
            label: 'Chainlength Calculated on Submit',
        };

        FirebaseAnalytics('Custom_Chainlength_Calculation', properties);

        if (window.woopra) {
            window.woopra.track('Custom_Chainlength_Calculation', properties);
        }

        Logger.log('CustomChainLengthFormFields', eventFields);
    }

    const handleSetState = (value: any) => {
        if (!value) return;
        switch (modalView) {
            case 'DRIVETRAIN_TYPE':
                setState({ selectedChainRingSize: '', selectedChainStayLength: '', selectedDriveTrainType: value });
                break;
            case 'CHAINRING_SIZE':
                setState({ selectedChainRingSize: value.label });
                break;
            case 'CHAINSTAY_LENGTH':
                setState({ selectedChainStayLength: value.label });
                break;
            default:
                break;
        }
    };

    const getOptionsList = () => {
        switch (modalView) {
            case 'DRIVETRAIN_TYPE':
                return ['XPLR', 'EAGLE_TRANSMISSION'];
            case 'CHAINRING_SIZE':
                return chainRingOptions;
            case 'CHAINSTAY_LENGTH':
                return chainStayOptions;
            default:
                return [];
        }
    };

    async function calculateChainLength() {
        try {
            const chainLengthData = await fetchChainLengths({
                selectedChainRingSize,
                selectedChainStayLength,
            });

            const category = isEagleTransmission ? 1 : 4;
            const frameCategories = await fetchOEMFrameCategories(category);

            if (frameCategories) {
                if (checkCookieConsent(cookieConsent) || isAuthenticated()) eventLogger();
            }

            if (chainLengthData?.chainLength && frameCategories) {
                // eslint-disable-next-line max-len
                navigate(`/guides/mobile/fullmount/chain/calculator/setup?driveTrainType=${selectedDriveTrainType}&chainLength=${chainLengthData.chainLength}&chainStayLength=${Number(selectedChainStayLength.split('mm')[0])}&setupCogPosition=${frameCategories.setup_cog_position}&setupCogPositionText=${frameCategories.setup_cog_position_text}&setupKey=${frameCategories.setup_key_position}&setupKeyImage=${frameCategories.setup_key_position_image}&supportedChainRings=${chainLengthData.supportedChainrings.join(',')}`);
                return;
            }

            setState({ showDataUnavailableModal: true });
        } catch (error) {
            Logger.error('Error fetching chain lengths', error);
        }
    }

    const renderOptions = (sectionHeader: string, value: string | number) => (
        <ContentContainer key={`${sectionHeader}-${value}`} className={styles.optionsContainer}>
            {sectionHeader === 'DRIVETRAIN_TYPE' && (
                <SelectionModalAlt
                    key={`${sectionHeader}-alt`}
                    open={openSelectionModal && modalView === 'DRIVETRAIN_TYPE'}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={['XPLR', 'EAGLE_TRANSMISSION']}
                />
            )}
            <div
                role="button"
                className={styles.row}
                tabIndex={0}
                onClick={() => {
                    setState({ openSelectionModal: true });
                    setOpenBikeTypeSelection(false);
                    setModalView(sectionHeader);
                }}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        setState({ openSelectionModal: true });
                        setOpenBikeTypeSelection(false);
                        setModalView(sectionHeader);
                    }
                }}
            >
                <div className={styles.selectorTitle}>
                    <Translate>{sectionHeader}</Translate>
                </div>
                <div className={styles.selectorTitle}>
                    <Translate>{value || '-- --'}</Translate>
                </div>
            </div>
        </ContentContainer>
    );

    const checkUrlParams = () => {
        const rdType = currentQueryParameters.get('rd_type');

        if (rdType) {
            setState({ selectedDriveTrainType: rdType });
        } else {
            navigate('/guides/mobile/fullmount/chain/calculator');
        }

        setState({
            selectedChainRingSize: currentQueryParameters.get('selectedChainringSize'),
            selectedChainStayLength: currentQueryParameters.get('selectedChainstay'),
        });
    };

    useEffect(() => {
        if (isBikeTypeSelectionOpen) setState({ openSelectionModal: false });
    }, [isBikeTypeSelectionOpen]);

    useEffect(() => {
        resetState();
    }, [selectedBikeType]);

    useEffect(() => {
        if (currentQueryParameters) checkUrlParams();
    }, []);

    return (
        <>
            <DataUnavailableModal
                open={showDataUnavailableModal}
                onClose={() => setState({ showDataUnavailableModal: false })}
                onConfirm={() => setState({ showDataUnavailableModal: false })}
                header="Data Unavailable"
                infoText="MOBILE_DATA_UNAVAILABLE_DROPBAR"
                options={false}
            />
            { (isFetching) && <Spinner Component={FadeLoader} margin={-12} height={6} width={2} />}

            <>

                <SelectionModal
                    open={openSelectionModal && modalView !== 'DRIVETRAIN_TYPE'}
                    modalTitle={modalView}
                    onClose={() => setState({ openSelectionModal: false })}
                    setOption={handleSetState}
                    list={getOptionsList()}
                />
                {renderOptions('DRIVETRAIN_TYPE', selectedDriveTrainType)}
                {selectedDriveTrainType
                   && (
                       <>
                           {renderOptions('CHAINSTAY_LENGTH', selectedChainStayLength)}
                           {renderOptions('CHAINRING_SIZE', selectedChainRingSize)}
                       </>
                   )}
                {selectedBikeType && (
                    <div className={styles.formBtns}>
                        <div className={styles.footer}>
                            <button
                                className={styles.submitButton}
                                type="button"
                                onClick={() => calculateChainLength()}
                                disabled={disableCalcButton}
                                style={{ backgroundColor: disableCalcButton ? SRAM_500 : WHITE }}
                            >
                                <Translate>CALCULATE</Translate>
                            </button>
                            <button
                                className={styles.resetBtn}
                                type="button"
                                onClick={() => {
                                    setSelectedBikeType('');
                                    clearParams();
                                }}
                            >
                                <Translate>RESET</Translate>
                            </button>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};

export default DropBarFlow;
